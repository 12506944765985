.gallery_image {
  width: 100vw;
  max-width: 1440px;
  aspect-ratio: 2.21230769231 / 1;
}

.gallery_slide_container {
  position: relative;
}

.gallery_slide_text {
  position: absolute;
  top: 18.502202643%;
  left: 9.236111111%;
  max-width: 100%;
  color: #23292a;
  text-align: left;
  font-size: 24px;
  line-height: 130%;
  font-weight: 900;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .gallery_slide_text {
    font-size: 48px;
    letter-spacing: -0.02em;
  }
}
@media screen and (min-width: 992px) {
  .gallery_slide_text {
    font-size: 52px;
    line-height: 54px;
    letter-spacing: 0.01em;
  }
}

.gallery_slide_text a {
  display: inline-block;
  margin-top: 54px;
  margin-left: 5px;
  padding: 12px 84px 12px 84px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.075em;
  text-decoration: none;
  background-color: #23292a;
}
