.header {
  display: none;
  padding: 30px 20px 20px 20px;
  height: 147px;
  box-sizing: border-box;
  position: relative;
  color: #231f20;
  transition: background-color 0.3s ease-in-out;
  z-index: 10;
  position: relative;
}
@media screen and (min-width: 1120px) {
  .header {
    padding: 30px 52px 20px 40px;
  }
}

@media screen and (min-width: 992px) {
  .header {
    display: flex;
  }
}

.header_logo {
  margin-bottom: 10px;
  margin-top: auto;
}
.header_logo svg {
  width: 100px;
  height: 44px;
}
@media screen and (min-width: 1120px) {
  .header_logo svg {
    width: 200px;
    height: 88px;
  }
}

.header_list {
  box-sizing: border-box;
  display: flex;
  margin: 0;
  margin-left: auto;
  z-index: 2;
}

.header_list li {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: end;
}

.header_item {
  font-size: 14px;
  line-height: 27px;
  letter-spacing: 0.075em;
  font-style: normal;
  font-weight: 700;
  padding: 0 10px;
}
@media screen and (min-width: 1160px) {
  .header_item {
    font-size: 16px;
  }
}
@media screen and (min-width: 1343px) {
  .header_item {
    font-size: 18px;
    padding: 0 17px;
  }
}

.header_item a,
.header_item_text {
  position: relative;
  cursor: default;
}

.header_item a {
  cursor: pointer;
}

.header_item a:hover {
  color: #0084c9;
}

/* ************ */
.header_items_bg {
  display: none;
  position: absolute;
  top: calc(100% - 16px);
  left: 0;
  height: 450px;
  width: 100%;
  background: #fff;
  z-index: 1;
  padding-top: 16px;
}
@media screen and (min-width: 1160px) {
  .header_items_bg {
    height: 400px;
  }
}
.header:has(.header_item_with_items:hover) .header_items_bg {
  display: block;
}

.header_item_with_items {
  position: relative;
}
.header_items {
  display: none;
  position: absolute;
  top: calc(100% - 2px);
  left: -13px;
  padding: 32px 30px 30px 30px;
  z-index: 1;
  width: max-content;
}

.header_item:hover .header_items {
  display: block;
}

.header_link {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  margin-bottom: 25px;
  max-width: 250px;
}
@media screen and (min-width: 1160px) {
  .header_link {
    font-size: 16px;
    max-width: none;
    line-height: 16px;
  }
}
.header_item_with_items:hover .header_item_text,
.header_item_with_items:has(.link_current) .header_item_text {
  color: #0084c9;
}
