.product_category_page_slide {
  background-color: #e5e5e5;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 1100px) {
  .product_category_page_slide {
    height: 519px;
  }
}

.product_category_page_slide_text {
  max-width: 900px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}
@media screen and (min-width: 1100px) {
  .product_category_page_slide_text {
    position: absolute;
    padding: 0;
    left: 7.430555555%;
    top: 85px;
  }
}
.product_category_page_title {
  line-height: 54px;
  font-weight: 900;
  text-transform: uppercase;
}
.product_category_page_subtitle {
  max-width: 550px;
}
.product_category_page_slide_image {
  max-height: 519px;
}
@media screen and (min-width: 1100px) {
  .product_category_page_slide_image {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

/**********/

.self_priming_pumps {
  padding: 40px 20px;
  gap: 27px;
  display: grid;
  grid-template-columns: 1fr;
}
@media screen and (min-width: 740px) {
  .self_priming_pumps {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .self_priming_pumps {
    padding: 80px 40px;
  }
}
.self_priming_pumps_image {
  width: 100%;
  aspect-ratio: 1.50338600451 / 1;
}
.self_priming_pumps_content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  text-transform: none;
}
.self_priming_pumps_content h2 {
  max-width: 550px;
}
