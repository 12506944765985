.contact_us_image {
  width: calc(100% + 40px);
  aspect-ratio: 2 / 1;
  margin: 0 -20px;
  max-height: 319px;
}
@media screen and (min-width: 992px) {
  .contact_us_image {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 720px) {
  .contact_us_image {
    max-height: 519px;
  }
}


.contact_us_shifted_content {
  max-width: 1023px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  padding: 0 20px;
}
@media screen and (min-width: 1115px) {
  .contact_us_shifted_content {
    margin-right: 40px;
    padding: 0;
  }
}

.contact_us_doubled_block {
  display: flex;
  margin-top: 87px;
  margin-bottom: 100px;
  flex-direction: column;
  gap: 20px;
}

@media screen and (min-width: 992px) {
  .contact_us_doubled_block {
    flex-direction: row;
    gap: 0;
  }
}

.contact_us_content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 14px;
  color: #23292a;
}
.contact_us_content h1 {
  margin-bottom: 12px;
}
.contact_us_content a {
  text-decoration: none;
  font-weight: 500;
}

.contact_us_map {
  aspect-ratio: 1.45888594164 / 1;
}
@media screen and (min-width: 992px) {
  .contact_us_map {
    width: 53.76344086%;
    margin-left: auto;
  }
}

.contact_us_separator {
  height: 1.67px;
  width: 100%;
  background: #323637;
  margin-bottom: 32px;
}

.contact_us_image_second {
  width: calc(100% - 40px);
  aspect-ratio: 2 / 1;
  margin: 30px 20px 30px 20px;
}

@media screen and (min-width: 992px) {
  .contact_us_image_second {
    width: 100%;
    margin: 60px 40px 30px 40px;
    width: calc(100% - 80px);
  }
}

.contact_us_legend {
  display: flex;
  gap: 28px;
  margin-bottom: 37px;
}
.contact_us_legend_item {
  display: flex;
  gap: 16px;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 22px;
  letter-spacing: 0.015em;
  align-items: center;
  font-weight: 700;
}
.contact_us_legend_item_circle {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0583c6;
}
.contact_us_legend_item_circle_orange {
  background: #ee7a24;
}

.contact_us_content_second {
  margin-bottom: 60px;
}
.contact_us_form_title {
  max-width: 580px;
  margin-bottom: 47px;
}
/* ************************ */
.contact_us_form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  row-gap: 40px;
  margin-bottom: 113px;
}
.contact_us_success {
  margin-bottom: 113px;
}
@media screen and (min-width: 992px) {
  .contact_us_form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.contact_us_form_input {
  box-sizing: border-box;
  height: 60px;
  padding: 9.5px 0 9.5px 0;
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  letter-spacing: 0.075em;
  border-width: 1px;
  border-style: solid;
  border-color: transparent transparent #23292a transparent;
}
.contact_us_form_input::placeholder {
  color: rgba(0, 0, 0, 0.8);
  font-size: 16px;
  letter-spacing: 0.075em;
  text-transform: capitalize;
}
.contact_us_form_area {
  grid-column-start: 1;
  grid-column-end: 3;
  resize: none;
  height: 112px;
}
.contact_us_form_button {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 50px;
  padding: 12px 62px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #231f20;
  font-size: 15px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  border: #23292a 1px solid;
  font-weight: 700;
  background-color: transparent;
  margin-left: auto;
  margin-top: 10px;
  cursor: pointer;
}

.contact_us_iframe {
  width: 100%;
  height: 100%;
  border: none;
}
