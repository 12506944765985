.link {
  color: inherit;
  text-decoration: none;
  text-transform: uppercase;
  display: block;
}
.link_current {
  color: #0084c9;
}
.content {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
}
.content a {
  color: inherit;
  font-weight: 700;
  text-underline-offset: 4px;
}
.content h1 {
  font-size: 52px;
  line-height: 54px;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.content h2,
.content h3,
.content h4,
.content h5,
.content h6,
.subtitle {
  font-size: 24px;
  line-height: 130%;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.content ul {
  margin: 0;
  padding-left: 16px;
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.015em;
  margin-left: 5px;
}
.content li {
  padding-left: 16px;
  margin-bottom: 8px;
  color: inherit;
  text-align: left;
  vertical-align: text-top;
  font-size: 16px;
  letter-spacing: 0.015em;
}
.title {
  font-size: 52px;
  line-height: 120%;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.date {
  color: rgba(0, 0, 0, 0.4);
  font-size: 15px;
  letter-spacing: 0.015em;
  line-height: 22px;
}
.error {
  color: #ff2d55 !important;
  border-color: transparent transparent #ff2d55  transparent !important;
}
.error::placeholder {
  color: #ff2d55 !important;
}

/* ********************************************************* */
.main_template {
  max-width: 1440px;
  margin: 0 auto;
}
.shifted_content {
  max-width: 897px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}
@media screen and (min-width: 1115px) {
  .shifted_content {
    margin-right: 10.833333333%;
  }
}
/*

.shifted_title {
  margin-left: auto;
  margin-right: auto;
  max-width: 1026px;
}
@media screen and (min-width: 992px) and (max-width: 1066px) {
  .shifted_title {
    padding: 0 20px;
  }
}

@media screen and (min-width: 1226px) {
  .shifted_title {
    margin-right: 8.125%;
  }
} */
