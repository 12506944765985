.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 11;
}
.menu_spacer {
  width: 100%;
  height: 59px;
}
@media screen and (min-width: 992px) {
  .menu {
    display: none;
  }
  .menu_spacer {
    display: none;
  }
}

.menu_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 59px;
}
.menu_list {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 2px 20px 29px 20px;
  gap: 37px;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  left: 0;
  background: #fff;
  z-index: 1;

  opacity: 0;
  margin-top: -40px;
  pointer-events: none;

  max-height: calc(100vh - 60px);
  overflow: auto;
  overscroll-behavior: none;
}
.menu_list li {
  list-style: none;
  font-size: 18px;
  letter-spacing: 0.075em;
  font-weight: 700;
  text-transform: uppercase;
}

.menu_logo {
  transition: all 0.3s ease-in-out;
  margin-left: 21px;
}
.menu_logo svg {
  width: 62px;
  height: 28px;
}

.menu_burger {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 20px 20px 19px 20px;
  overflow: visible;
  position: relative;
}

.menu_burger span,
.menu_burger span::before,
.menu_burger span::after {
  background: #000;
  display: block;
  height: 2px;
  border-radius: 2px;
  opacity: 1;
  position: absolute;
  transition: 0.3s ease-in-out;
}

.menu_burger span::before,
.menu_burger span::after {
  content: "";
}

.menu_burger span {
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  width: 26px;
}

.menu_burger span::before {
  left: 0;
  top: -12px;
  width: 26px;
}

.menu_burger span::after {
  left: 0;
  top: 12px;
  width: 26px;
}

.menu_opened .menu_burger span {
  transform: rotate(-45deg);
  top: 12px;
  width: 26px;
}

.menu_opened .menu_burger span::before {
  top: 0;
  transform: rotate(90deg);
  width: 26px;
}

.menu_opened .menu_burger span::after {
  top: 0;
  left: 0;
  transform: rotate(90deg);
  opacity: 0;
  width: 0;
}

.menu_opened .menu_list {
  opacity: 1;
  margin-top: 0;
  pointer-events: unset;
}
.menu_opened .menu_logo {
  opacity: 0;
}
/* ********** */

.menu_item_collapsible {
  overflow: hidden;
}

.menu_item_collapsible input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.menu_item_collapsible-item-label {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: 0.075em;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
}

.menu_item_collapsible-item-content {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.075em;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 0;
  transition: all 0.35s;
  padding-left: 16px;
}

.menu_item_collapsible-item-content a {
  text-decoration: none;
}

input:checked ~ .menu_item_collapsible-item-content {
  max-height: 100vh;
  padding-top: 37px;
}
