.footer {
  color: #000;
  background-color: rgba(232, 232, 232, 0.6);
  margin-top: auto;
}

.footer_content {
  padding: 20px 20px 27px 20px;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  box-sizing: border-box;
}
@media screen and (min-width: 992px) {
  .footer_content {
    padding: 40px 40px 47px 40px;
  }
}

.footer_separator {
  height: 1.67px;
  width: 100%;
  background: #323637;
}

.footer_links_area {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media screen and (min-width: 1180px) {
  .footer_links_area {
    flex-direction: row;
  }
}

.footer_links_block {
  width: 186px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  font-size: 14px;
  line-height: 21px;
}

.footer_major_link {
  margin-bottom: 7px;
  letter-spacing: 0.075em;
  font-weight: 700;
}

.footer_link {
  color: #828282;
  text-transform: none;
  letter-spacing: 0.015em;
}

.footer_contact_us {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 38px;
}
.footer_contact_us a {
  color: inherit;
}

.footer_bottom_links {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  gap: 20px;
}

@media screen and (min-width: 992px) {
  .footer_bottom_links {
    margin-top: 56px;
    flex-direction: row;
    gap: 0;
  }
}

.footer_copyright {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.075em;
}

.footer_legal_links {
  margin-top: auto;
  display: flex;
  gap: 12px;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  line-height: 21px;
}
.footer_link_separator {
  height: 10px;
  width: 1px;
  background: #828282;
}
