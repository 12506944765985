@import "normalize.css/normalize.css";

body {
  height: 100%;
  width: 100%;
  background: #fff;
  color: #23292A;
  font-family: "Circe", sans-serif;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

img {
  display: block;
  object-fit: cover;
}
/* fonts */
@font-face {
  font-family: "Circe";
  src: url("./static/fonts/CRC35.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Circe";
  src: url("./static/fonts/CRC55.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Circe";
  src: url("./static/fonts/CRC65.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Circe";
  src: url("./static/fonts/CRC75.otf");
  font-weight: 900;
}
