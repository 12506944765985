.posts_page {
  max-width: 100%;
  margin: 0 auto;
  padding: 26px 20px 50px 20px;
  box-sizing: border-box;
}
.posts_page_title{
  margin-bottom: 55px;
}

@media screen and (min-width: 992px) {
  .posts_page {
    padding: 26px 40px 150px 40px;
  }
}
