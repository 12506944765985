.featured_products_title {
  margin: 0 0 54px 23px;
}
@media screen and (min-width: 992px) {
  .featured_products_title {
    margin: 0 0 54px 43px;
  }
}

.featured_products {
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr;
  color: #000;
  padding: 0 20px 50px 20px;
}

@media screen and (min-width: 640px) {
  .featured_products {
    gap: 29px;
    row-gap: 31px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .featured_products {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 40px 80px 40px;
  }
}
.featured_products_item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.featured_products_item_image {
  width: 100%;
  aspect-ratio: 0.864/1;
  object-fit: contain;
}
