.huge_products {
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr;
  color: #000;
  padding: 40px;
  margin-bottom: 73px;
}

.huge_products_item {
  display: flex;
  gap: 28px;
}
.huge_products_item_image {
  width: calc((100% - 28px) / 2);
  aspect-ratio: 0.74330357142/1;
  object-fit: contain;
}
.huge_product_content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  text-transform: none;
}
.huge_product_content p {
  line-height: 22px;
}
