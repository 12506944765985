.big_products {
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr;
  color: #000;
  padding: 40px 20px 50px 20px;
  margin-bottom: 66px;
}

@media screen and (min-width: 640px) {
  .big_products {
    gap: 29px;
    row-gap: 31px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .big_products {
    padding: 40px 40px 50px 40px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.big_products_item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.big_products_item_image {
  width: 100%;
  aspect-ratio: 0.864/1;
  object-fit: contain;
}
.big_products_item_name {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 0.01em;
}
.big_products_item_text {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  color: rgba(0, 0, 0, 0.8);
}
