.support {
  margin-top: 76px;
  padding: 0 20px;
}
.support h2 {
  margin-bottom: 12px;
}

@media screen and (min-width: 992px) {
  .support {
    padding: 0;
  }
}

.support h1 {
  margin-bottom: 24px;
}
.support_link {
  display: flex;
  gap: 26px;
  align-items: center;
  text-transform: none;
  text-decoration: none;
  margin-bottom: 80px;
}
.support_link p {
  font-size: 24px;
  letter-spacing: 0.015em;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 700;
  max-width: 380px;
}
.support_link_image {
  width: 203px;
  aspect-ratio: 0.70731707317 / 1;
  flex-shrink: 0;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}
.support_link_image_second {
  width: 203px;
  aspect-ratio: 1.78070175439 / 1;
  flex-shrink: 0;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
}
/* ************** */
.collapsible {
  border-bottom: 1px solid #000000;
  margin-bottom: 70px;
}
.collapsible-accordion {
  overflow: hidden;
  border-top: 1px solid #000000;
}

.collapsible-accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.collapsible-item-label {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  font-size: 24px;
  line-height: 22px;
  letter-spacing: 0.015em;
  font-weight: 700;
  cursor: pointer;
  position: relative;
}
.collapsible-item-label::before,
.collapsible-item-label::after {
  content: "";
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  transition: all 0.35s;
}
.collapsible-item-label::after {
  width: 3px;
  height: 12px;
  top: 35px;
  transform: translate(-50%, -50%);
  right: 7px;
}
.collapsible-item-label::before {
  width: 12px;
  height: 3px;
  top: 35px;
  transform: translateY(-50%);
  right: 4px;
}
.collapsible-item-content {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  display: flex;
  flex-direction: column;
  gap: 0px;
  max-height: 0;
  transition: all 0.35s;
}
.collapsible-item-content h2,
.collapsible-item-content h3,
.collapsible-item-content h4,
.collapsible-item-content h5,
.collapsible-item-content h6 {
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  font-weight: 700;
  margin: 24px 0;
}
.collapsible-item-content a {
  text-decoration: none;
  font-weight: 500;
}

input:checked + .collapsible-item-label::after {
  height: 0;
}
input:checked ~ .collapsible-item-content {
  max-height: 500vh;
  padding-bottom: 24px;
}
