.product_page {
  padding: 40px 40px 200px 40px;
}
/* ******* */
.product_page_first_screen {
  gap: 28px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
}
@media screen and (min-width: 640px) {
  .product_page_first_screen {
    grid-template-columns: repeat(2, 1fr);
  }
}

.product_page_first_screen_image {
  width: 100%;
  aspect-ratio: 0.74330357142 / 1;
  object-fit: contain;
}

.product_page_first_screen_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product_page_first_screen_content_image {
  width: 100%;
  aspect-ratio: 1.665 / 1;
  object-fit: contain;
}
/* ********* */
.product_page_table_first {
  margin: 24px 0 71px 0;
}
/* ******* */
.product_page_second_screen {
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  align-items: center;
}

@media screen and (min-width: 640px) {
  .product_page_second_screen {
    grid-template-columns: repeat(2, 1fr);
  }
}

.product_page_second_screen_image {
  width: 100%;
  aspect-ratio: 0.580976863752 / 1;
  object-fit: contain;
  max-height: 100vh;
}

/* ********* */
.product_page_material_of_construction {
  gap: 22px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 10px;
  align-items: center;
}
@media screen and (min-width: 640px) {
  .product_page_material_of_construction {
    grid-template-columns: repeat(2, 1fr);
  }
}

.product_page_material_of_construction_image {
  width: 100%;
  aspect-ratio: 0.88446215139 / 1;
  object-fit: contain;
}

/* ************************************* */

table,
th,
td {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

th {
  padding: 18px 12px;
  background: #f4f4f4;
}
td {
  padding: 12px;
}

th,
td {
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.015em;
  white-space: nowrap;
}

table {
  margin-bottom: 5px;
  width: 100%;
}
