.manufacturing_facilities_image_horizontal {
  aspect-ratio: 1.85330578512 / 1;
}
.manufacturing_facilities_image_vertical {
  aspect-ratio: 0.74358974359 / 1;
  width: calc( (100% - 28px) / 2)
}
.manufacturing_facilities_images_block {
  display: flex;
  gap: 28px;
  margin-top: 34px;
  margin-bottom: 45px;
}
