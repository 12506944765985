.post_page_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #23292a;
  margin: 45px 0 62px 0;
  padding: 0 20px;
}
.post_page_content p {
  margin-bottom: 10px;
}

@media screen and (min-width: 992px) {
  .post_page_content {
    padding: 0;
  }
}

.post_page_content_image {
  object-fit: contain;
  align-self: flex-start;
  max-width: 100%;
  max-height: 90vh;
}