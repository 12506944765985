.blog {
  display: grid;
  gap: 60px;
  grid-template-columns: 1fr;
  color: #000;
}
@media screen and (min-width: 640px) {
  .blog {
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .blog {
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
}

.blog_item {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.blog_item_image {
  width: 100%;
  aspect-ratio: 1.421052631587/1;
}
.blog_item_text {
  color: rgba(0, 0, 0, 0.8);
}