.legal_page_content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #23292a;
  margin: 26px 0 136px 0;
  padding: 0 20px;
}

@media screen and (min-width: 992px) {
  .legal_page_content {
    padding: 0;
  }
}
