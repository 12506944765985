/* --------------------------------------------------------------------------------------------------- */
.index_page_first_screen {
  box-sizing: border-box;
  overflow: hidden;
}
/* --------------------------------------------------------------------------------------------------- */
.index_page_second_screen {
  display: flex;
  flex-direction: column-reverse;
}
@media screen and (min-width: 992px) {
  .index_page_second_screen {
    padding: 126px 156px 126px 127px;
    gap: 114px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.index_page_second_screen_image {
  flex-shrink: 0;
  width: 100%;
  aspect-ratio: 1.07877461707 / 1;
  margin-top: 10px;
}
.index_page_second_screen_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

@media screen and (min-width: 992px) {
  .index_page_second_screen_content {
    padding: 0;
  }
}
/* --------------------------------------------------------------------------------------------------- */
.index_page_third_screen {
  padding: 50px 40px 108px 40px;
  background: #23292a;
}
.index_page_third_screen_separator {
  height: 1.67px;
  width: 100%;
  background: #fff;
  margin-bottom: 29px;
}
.index_page_third_screen_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  width: 100%;
  max-width: 796px;
  margin-bottom: 40px;
}
.index_page_third_screen_grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 40px;
}

@media screen and (min-width: 992px) {
  .index_page_third_screen_grid {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.index_page_third_screen_grid_item {
  position: relative;
}
.index_page_third_screen_grid_item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1;
  transition: opacity 0.2s ease-in-out;
}
.index_page_third_screen_grid_item:hover::after {
  opacity: 0.5;
}
.index_page_third_screen_grid_item_image {
  aspect-ratio: 1.18226600985 / 1;
  width: 100%;
  display: block;
}

.index_page_third_screen_grid_item_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  z-index: 2;
}
/* --------------------------------------------------------------------------------------------------- */
.index_page_fourth_screen {
  background: #e8e8e8;
  display: flex;
  gap: 0px;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .index_page_fourth_screen {
    padding: 0;
    flex-direction: row;
    height: 654px;
    gap: 119px;
  }
}
.index_page_fourth_screen_image {
  aspect-ratio: 0.94036697247 / 1;
}
.index_page_fourth_screen_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

@media screen and (min-width: 992px) {
  .index_page_fourth_screen_content {
    max-width: 550px;
  }
}

.index_page_fourth_screen_button {
  height: 50px;
  padding: 12px 62px;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #231f20;
  font-size: 15px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  border: #23292a 1px solid;
  font-weight: 700;
}
@media screen and (min-width: 992px) {
  .index_page_fourth_screen_button {
    margin-top: 40px;
  }
}
/* --------------------------------------------------------------------------------------------------- */
.index_page_fifth_screen {
  max-width: 100%;
  margin: 0 auto;
  padding: 32px 20px 20px 20px;
  box-sizing: border-box;
}

@media screen and (min-width: 992px) {
  .index_page_fifth_screen {
    padding: 32px 40px 20px 40px;
  }
}

.index_page_fifth_screen_separator {
  height: 1.67px;
  width: 100%;
  background: #323637;
  margin-bottom: 64px;
}

.index_page_fifth_screen_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
}
.index_page_fifth_screen_link {
  text-transform: capitalize;
}
.index_page_fifth_screen_title_button {
  height: 50px;
  padding: 12px 62px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #231f20;
  font-size: 15px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  border: #23292a 1px solid;
  font-weight: 700;
}
