.about_page_content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #23292a;
  padding: 40px 20px;
}

@media screen and (min-width: 992px) {
  .about_page_content {
    padding: 87px 0 42px 0;
  }
}

.about_page_content_image {
  width: 100%;
  margin: 0;
  aspect-ratio: 2 / 1;
  max-height: 319px;
}
@media screen and (min-width: 720px) {
  .about_page_content_image {
    max-height: 519px;
  }
}
