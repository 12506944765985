.industries_page_page_slide {
  height: 319px;
  position: relative;
}

.industries_page_page_slide::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-color: #000000;
  opacity: 0.4;
}

@media screen and (min-width: 720px) {
  .industries_page_page_slide {
    height: 519px;
  }
}

.industries_page_page_slide_text {
  position: absolute;
  left: 7.430555555%;
  top: 96px;
  max-width: 900px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  line-height: 54px;
  font-weight: 900;
  text-transform: uppercase;
  max-width: 926px;
  color: #fff;
  font-size: 36px;
  z-index: 1;
}

@media screen and (min-width: 700px) {
  .industries_page_page_slide_text {
    font-size: 52px;
  }
}

.industries_page_page_slide_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 2.77456647399 / 1;
}
/* ****************** */
.industries_page {
  padding: 40px 20px 30px 20px;
  gap: 27px;
  display: grid;
  grid-template-columns: 1fr;
}

@media screen and (min-width: 740px) {
  .industries_page {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 992px) {
  .industries_page {
    padding: 80px 40px 60px 40px;
  }
}

.industries_page_title {
  max-width: 460px;
  line-height: 54px;
}
.industries_page_content {
  display: flex;
  flex-direction: column;
  gap: 36px;
  text-transform: none;
}
.industries_page_content h2 {
  max-width: 550px;
}
